<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">发证机构管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
          <div class="searchbox">
            <div title="发证机构名称" class="searchboxItem">
              <span class="itemLabel" style="min-width: 8em;">发证机构名称:</span>
              <el-input v-model="authorityName" type="text" clearable size="small" placeholder="请输入发证机构名称" />
            </div>
            <div>
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="handleAddOrEdit()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" min-width="100" />
              <el-table-column label="发证机构名称" align="left" prop="authorityName" show-overflow-tooltip min-width="160" />
              <el-table-column label="发证机构名称(英文)" align="left" prop="authorityNameEn" show-overflow-tooltip
                min-width="160" />
              <el-table-column label="创建日期" align="left" prop="createTime" show-overflow-tooltip
                min-width="160" />
              <el-table-column label="操作" align="center" width="220px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="handleAddOrEdit(scope.row)">编辑</el-button>
                  <el-button :disabled="!scope.row.canDeleted" type="text" style="padding: 0px 5px" size="mini"
                    @click="handleDel(scope.row.authorityId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "issuingAuthorityList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 发证机构名称
      authorityName: "",
    };
  },
  computed: {

  },
  mounted() {
    
  },
  watch: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.authorityName) {
        params.authorityName = this.authorityName
      }
      this.doFetch({
        url: "/biz/cms/authority/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 新增&编辑
    handleAddOrEdit(row) {
      this.$router.push({
        path: "/web/certificateManage/issuingAuthorityListAddEdit",
        query: {
          authorityId: row?.authorityId ?? ""
        }
      });
    },
    // 删除
    handleDel(id) {
      this.doDel({
        title: "删除",
        url: "/biz/cms/authority/delete",
        msg: "是否确认删除该发证机构",
        ps: {
          type: "post",
          data: { authorityId: id },
        },
      }, true, 6);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/web/certificateManage/issuingAuthorityListAddEdit') {
        vm.getData(vm.pageNum)
      }
    })
  }
};
</script>
<style lang="less" scoped></style>
